import React from "react"
import Layout from "../components/layout"
import SEO from "../components/seo"
import { useStaticQuery, graphql } from "gatsby"
import Hero from "../components/Hero"
import SectionHeading from "../components/SectionHeading"
import Quote from "../components/Quote"
import InfoBlock from "../components/InfoBlock"
import Benefits from "../components/Benefits"
import Faq from "../components/Faq"

const IndexPage = () => {
  return (
    <Layout>
      <SEO title="Home" />
      <div className="">
        <Hero />
        <SectionHeading id="powerOfSport" sectionHeading="Power of Sport" />
        <Quote
          quote="We cannot always build the future for our youth, but we can build our youth for the future."
          author="Franklin D Roosevelt"
          position="Predident of the United States of America"
        />
        <InfoBlock
          paragraph1="More than 41% of the world’s 7.7 billion people are under the age of 25. In a rapidly changing world, these young people face all kinds of struggles and challenges in the areas of identity, relationships, sexuality, drugs, alcohol, unemployment, family breakdown, poverty, internet gaming, stress, uncertainty and a lack of direction and hope for the future."
          paragraph2="The future of a nation lies in the hands of its youth. So how can we support and mentor the world’s young people into lives of purpose, significance and meaning? We believe that a powerful relationship develops between a sports coach and his or her players, providing a context in which a coach can have a positive and life-transforming influence on the young people they mentor. Put simply: sport has the power to change lives!"
        />
        <SectionHeading
          id="benefitsOfSport"
          sectionHeading="Benefits of Sport"
        />
        <Benefits />
        <SectionHeading id="help" sectionHeading="How Can We Help?" />
        <Faq />
        <div
          style={{ backgroundImage: 'url("/triangles.jpg")' }}
          className="flex flex-col items-center p-16 text-center text-white bg-indigo-800"
        >
          <h2 className="mb-1 text-4xl font-medium text-blue-600">
            World Population
          </h2>
          <h2 className="mb-4 text-2xl font-normal text-gray-600">
            Under the Age of 25
          </h2>
          <h2 className="mb-8 text-6xl font-bold text-gray-300">
            3,200,000,000
          </h2>
        </div>
      </div>
    </Layout>
  )
}

export default IndexPage
