import React from "react"
export default function Faq(props) {
  return (
    <div>
      <div className="bg-white">
        <div className="max-w-screen-xl mx-auto pt-12 pb-16 sm:pt-16 sm:pb-20 px-4 sm:px-6 lg:pt-20 lg:pb-28 lg:px-8">
          <dl className="md:grid md:grid-cols-2 md:gap-8">
            <div>
              <div>
                <dt className="text-lg leading-6 font-medium text-gray-900">
                  Understanding
                </dt>
                <dd className="mt-2 h-auto md:h-16">
                  <p className="text-base leading-6 text-gray-500">
                    We help coaches and community leaders understand how sport
                    can be a tool for building into the lives of young people,
                    with positive values and standards.
                  </p>
                </dd>
              </div>
              <div className="mt-12">
                <dt className="text-lg leading-6 font-medium text-gray-900">
                  Training
                </dt>
                <dd className="mt-2 h-auto md:h-16">
                  <p className="text-base leading-6 text-gray-500">
                    We train coaches, community leaders and volunteers in sports
                    coaching and developing young people holistically; giving
                    skills and understanding in how to start and develop
                    impactful community-based sports initiatives.
                  </p>
                </dd>
              </div>
              <div className="mt-12">
                <dt className="text-lg leading-6 font-medium text-gray-900">
                  Mentoring
                </dt>
                <dd className="mt-2 h-auto md:h-16">
                  <p className="text-base leading-6 text-gray-500">
                    We mentor those trained leaders and coaches by providing
                    on-going support, advice and training, as their community
                    sports initiative develops.
                  </p>
                </dd>
              </div>
            </div>
            <div className="mt-12 md:mt-0">
              <div>
                <dt className="text-lg leading-6 font-medium text-gray-900">
                  Resourcing
                </dt>
                <dd className="mt-2 h-auto md:h-16">
                  <p className="text-base leading-6 text-gray-500">
                    We provide resources to equip youth as individuals and
                    leaders and to train coaches in a variety of sports.
                  </p>
                </dd>
              </div>
              <div className="mt-12">
                <dt className="text-lg leading-6 font-medium text-gray-900">
                  Facilitating
                </dt>
                <dd className="mt-2 h-auto md:h-16">
                  <p className="text-base leading-6 text-gray-500">
                    We can facilitate camps and competitions for coaches and
                    community leaders to bring their young people to attend.
                  </p>
                </dd>
              </div>
              <div className="mt-12">
                <dt className="text-lg leading-6 font-medium text-gray-900">
                  Building Leadership
                </dt>
                <dd className="mt-2 h-auto md:h-16">
                  <p className="text-base leading-6 text-gray-500">
                    We offer training to build leadership capacity to help in
                    developing effective sports initiatives and leaders at the
                    local community level.
                  </p>
                </dd>
              </div>
            </div>
          </dl>
        </div>
      </div>
    </div>
  )
}
