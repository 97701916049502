import React from "react"
export default function Quote(props) {
  return (
    <div className="">
      <section className="py-12 overflow-hidden bg-gray-50 md:py-20 lg:py-24">
        <div className="relative px-4 mx-auto max-w-screen-xl sm:px-6 lg:px-8">
          <div className="relative">
            <blockquote className="mt-8">
              <div className="max-w-5xl mx-auto text-2xl font-medium text-center text-gray-900 leading-9">
                <h6
                  style={{ fontVariant: "small-caps" }}
                  className="text-2xl font-light md:text-3xl"
                >
                  &ldquo;{props.quote}&rdquo;
                </h6>
              </div>
              <footer className="mt-8">
                <div className="md:flex md:items-center md:justify-center">
                  <div className="md:flex-shrink-0"></div>
                  <div className="mt-3 text-center md:mt-0 md:ml-4 md:flex md:items-center">
                    <div>
                      <h5 className="text-lg font-normal text-gray-700 leading-6">
                        {props.author}
                      </h5>
                    </div>

                    <svg
                      className="hidden w-5 h-5 mx-1 text-indigo-600 md:block"
                      fill="currentColor"
                      viewBox="0 0 20 20"
                    >
                      <path d="M11 0h3L9 20H6l5-20z" />
                    </svg>

                    <div>
                      <h2 className="text-lg font-normal text-gray-500 leading-6">
                        {props.position}
                      </h2>
                    </div>
                  </div>
                </div>
              </footer>
            </blockquote>
          </div>
        </div>
      </section>
    </div>
  )
}
