import React from "react"
export default function SectionHeading(props) {
  return (
    <div className="bg-sectionHeading" id={props.id}>
      <div className="container mx-auto">
        <div className="px-4 sm:px-6 lg:px-8 py-4 lg:py-8">
          <h2 className="text-white text-center my-0">
            {props.sectionHeading}
          </h2>
        </div>
      </div>
    </div>
  )
}
