import React from "react"

//import { Parallax, Background } from "react-parallax"
//import { useStaticQuery, graphql } from "gatsby"
class InfoBlock extends React.Component {
  render() {
    return (
      <div>
        <div
          id="InfoBlock"
          className="pt-8 bg-position-sm"
          style={{ backgroundPosition: `0 -100px` }}
        >
          <div className="container mx-auto">
            <div className="px-4 mx-auto max-w-7xl sm:px-6 lg:px-8"></div>
            <div className="px-4 py-6 md:py-16 sm:px-6">
              <h4 className="text-lg font-medium text-white">
                {this.props.paragraph1}
              </h4>
              <h4 className="text-lg font-medium text-white">
                {this.props.paragraph2}
              </h4>
            </div>
          </div>
        </div>
      </div>
    )
  }
}

export default InfoBlock
