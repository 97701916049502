import React from "react"
export default function Hero(props) {
  return (
    <div id="hero" className="flex items-center justify-center h-screen">
      <div className="container mx-auto">
        <div className="flex justify-center w-2/3 mx-auto align-center">
          <div className="px-4 py-4 sm:px-6 lg:px-8 lg:py-8">
            <h1 className="my-0 mb-4 font-medium text-center text-white">
              Our Vision
            </h1>
            <h4 className="text-xl font-normal tracking-tight text-center text-white leading-7">
              To see a movement of community sports programs inspiring youth to
              live purposely for the good of their nation, community, family and
              selves.
            </h4>
            <hr className="fade" />
            <h1 className="my-0 mb-4 font-medium text-center text-white">
              Our Mission
            </h1>
            <h4 className="text-xl font-normal tracking-tight text-center text-white leading-7">
              To equip coaches and leaders to help young people develop
              holistically through community-based sports initiatives.
            </h4>
          </div>
        </div>
      </div>
    </div>
  )
}
