import React from "react"
import { Parallax, Background } from "react-parallax"
import { useStaticQuery, graphql } from "gatsby"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import {
  faChild,
  faBasketballBall,
  faHeartbeat,
  faUserShield,
} from "@fortawesome/free-solid-svg-icons"
export default function Benefits(props) {
  return (
    <div>
      <div id="benefits" className="overflow-hidden text-center bg-gray-50">
        <div className="relative px-4 py-12 mx-auto max-w-screen-xl sm:px-6 lg:px-8">
          <div className="relative lg:grid lg:grid-cols-2 lg:gap-x-8">
            <div className="mt-10 sm:grid sm:grid-cols-2 sm:gap-x-8 sm:gap-y-10 lg:col-span-2 lg:mt-0">
              <div>
                <div className="flex items-center justify-center w-16 h-16 mx-auto text-white rounded-full bg-sectionHeading">
                  <FontAwesomeIcon icon={faHeartbeat} size="2x" />
                </div>
                <div className="mt-5">
                  <h4 className="text-lg font-medium text-gray-900 leading-6">
                    HEALTHY
                  </h4>
                  <p className="max-w-xs mx-auto mt-2 text-base text-gray-500 leading-6">
                    Sport is good for health and well-being, and helps young
                    people grow in physical strength
                  </p>
                </div>
              </div>
              <div className="mt-10 sm:mt-0">
                <div className="flex items-center justify-center w-16 h-16 mx-auto text-white rounded-full bg-sectionHeading">
                  <FontAwesomeIcon icon={faUserShield} size="2x" />
                </div>
                <div className="mt-5">
                  <h4 className="text-lg font-medium text-gray-900 leading-6">
                    DEVELOPS VALUES
                  </h4>
                  <p className="max-w-xs mx-auto mt-2 text-base text-gray-500 leading-6">
                    Sport teaches positive values such as teamwork,
                    self-control, confidence and commitment
                  </p>
                </div>
              </div>
              <div className="mt-10 sm:mt-0">
                <div className="flex items-center justify-center w-16 h-16 mx-auto text-white rounded-full bg-sectionHeading">
                  <FontAwesomeIcon icon={faChild} size="2x" />
                </div>
                <div className="mt-5">
                  <h4 className="text-lg font-medium text-gray-900 leading-6">
                    DEVELOPS CHARACTER
                  </h4>
                  <p className="max-w-xs mx-auto mt-2 text-base text-gray-500 leading-6">
                    Sport helps young people to develop strong character,
                    leadership skills and respect for authority
                  </p>
                </div>
              </div>
              <div className="mt-10 sm:mt-0">
                <div className="flex items-center justify-center w-16 h-16 mx-auto text-white rounded-full bg-sectionHeading">
                  <FontAwesomeIcon icon={faBasketballBall} size="2x" />
                </div>
                <div className="mt-5">
                  <h4 className="text-lg font-medium text-gray-900 leading-6">
                    GROWS SKILLS
                  </h4>
                  <p className="max-w-xs mx-auto mt-2 text-base text-gray-500 leading-6">
                    Sport provides the opportunity to learn new skills
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
